import logo from './money.svg';
import './App.css';
import NumericInput from 'react-numeric-input';
import { LineChart } from 'react-chartkick';
import 'chartkick/chart.js';
import { useState } from 'react';

document.title = "How Much Do I Save";

function App() {
  
  const [data, setdata] = useState([]);
  const [mp, setmp] = useState([]);


  function calculate_payment(initialAmount, targetAmount, r, y) {
    var x = Math.pow(1 + r, y);
    var yearlyPayment = r * (targetAmount - (initialAmount * x)) / (x - 1);
    var monthlyPayment = yearlyPayment / 12.0;
    return monthlyPayment;
  }

  function generate_hypothetical(startingAge, endingAge, monthlyPayment, startingBalance, r) {
    var age = startingAge;
    var runningBalance = startingBalance;

    var rdata = [];

    rdata.push([age, runningBalance]);

    while (age < endingAge && age < 150) {
      runningBalance = runningBalance * (1 + r) + 12.0 * monthlyPayment;
      rdata.push([age+1, Math.round(runningBalance*100)/100]);
      age++;
    }

    return rdata;
  } 



  var handleChange= () => {
    
  }

  var fullCalc = () => {
    var ca = parseInt(document.getElementById('currentAge').value);
    var fa = parseInt(document.getElementById('futureAge').value);
    var sb = parseInt(document.getElementById('startingBalance').value.replace(',',''));
    var tb = parseInt(document.getElementById('targetBalance').value.replace(',',''));
    var r = parseInt(document.getElementById('rate').value)/100;

    console.log('Current Age', ca);
    console.log('Future Age', fa);
    console.log('Starting Balance', sb);
    console.log('Target Balance', tb);
    console.log('Rate of Return', r);

    var p = calculate_payment(sb, tb, r, fa - ca);

    setmp(Math.round(p*100)/100);

    setdata(generate_hypothetical(ca, fa, p, sb, r));
  }

  var click = () => {
    fullCalc();
  }

  return (
    
    
    <div className="App">
      <head>
      </head>
      <body>
        <br/>
        <div>Get started on a <a href="https://www.upromise.com?rb=147513325">529 College Savings account</a> </div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>
        <div>
          <h2>How Much Do I <span style={{color: "green"}}>$</span>ave?</h2>
          <div>a monthly planning estimator</div>
          <br/>
          <br/>
          <br/>
          <div><b>Starting Age</b><div class="tooltip" > &nbsp;(?)
            <span class="tooltiptext">The age at which you plan to start investing, whether for retirement or starting a college fund.</span>
          </div></div>
          <NumericInput id="currentAge" min={0} max={150} defaultValue={0} onChange={handleChange}/>
          <br/>
          <br/>
          <div><b>Future Age</b><div class="tooltip" > &nbsp;(?)
            <span class="tooltiptext">The age at which you desire to have a future nest egg balance, whether for retirement or college.</span>
          </div></div>
          <NumericInput id="futureAge" min={0} max={150} defaultValue={18} onChange={handleChange}/>
          <br/>
          <br/>
          <div><b>Starting Balance</b><div class="tooltip" > &nbsp;(?)
            <span class="tooltiptext">The amount of money investing at the starting age.</span>
          </div></div>
          <NumericInput id="startingBalance" min={0} max={1000000000} defaultValue={0} onChange={handleChange}/>
          <br/>
          <br/>
          <div><b>Future Balance</b><div class="tooltip" > &nbsp;(?)
            <span class="tooltiptext">The amount of money needed at the time of the "future age."</span>
          </div></div>
          <NumericInput id="targetBalance" min={0} max={1000000000} defaultValue={100000} onChange={handleChange}/>
          <br/>
          <br/>
          <div><b>Rate of Return (%)</b><div class="tooltip" > &nbsp;(?)
            <span class="tooltiptext">The average annual return of the asset you are planning to invest in--for instance, the S&amp;P 500 has averaged about 10&#37; per year.</span>
          </div></div>
          <NumericInput id="rate" min={0} max={100} defaultValue={10} onChange={handleChange}/>
          <br/>
          <br/>
          <br/>
          <button class="button" onClick={click}>Calculate</button>
        </div>
        <div>
          <br/>
          <br/>
          <div style={{color: "green"}}><b>{!mp ? '' : '$' + mp + ' per month'}</b><div class="tooltip" > &nbsp;(?)
            <span class="tooltiptext">This is an estimate based on the inputs you provided.</span>
          </div></div>
          <br/>
          <br/>
          <br/>
          <LineChart id="lineChart" data={data} xtitle="Age" ytitle="Balance"/>          
        </div>
      </div>
      <br/>
      <div>
            This is a planning tool used to estimate how much to invest in a broad index fund (or something similar)
            on a monthly basis
            in order to meet your financial goals, whether retirement, college savings, or other.  It is in no way 
            financial advice or any kind of substitute for financial advice.  It is only a tool used for estimation
            and is not indicative of reality.
          </div>
      </body>
    </div>
    
  );
}

export default App;
